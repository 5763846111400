import React from "react";

import { CardAbout } from "../../components/Cards";
const About = () => {
  return (
    <>
      <CardAbout />
    </>
  );
};

export default About;
